.groupMenu {
  /* background-color: #4C566A; */
  border: 1px solid #4C566A;
  border-radius: 10px;
  margin-right: 0.5rem;
}

.MuiButton-textPrimary {
  color: white !important;
}

.MuiButton-root {
  border-radius: 10px;
}

.MuiMenu-list {
  background-color: rgb(36, 41, 51);
  color: white;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  background-color: #4C566A !important;
}

.MuiListItem-button:hover {
  background-color: #4C566A !important;
}