.toolbar {
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgb(36, 41, 51);
  height: 56px;
  z-index: 100;
}

.toolbar_logo {
  margin-left: -0.4rem;
  margin-top: 0.4rem;
}

.toolbar_logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
}

.toolbar_navigation-items {
  color: white;
  background-color: #4C566A;
  border: 0.7rem solid #4C566A;
  border-radius: 10px;
  flex-direction: row;
  max-height: 1rem;
  font-size: 1rem;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: orange;
}

.spacer {
  flex: 1;
}

/* @media (max-width: 768px) {
  .toolbar_navigation-items {
    display: none;
  }
} */

@media (min-width: 769px) {
  .toolbar_toggle-button {
    display: none;
  }

  .toolbar_logo {
    margin-left: 0;
    margin-top: 5px;
  }
}
