main {
  padding-top: 64px;
}

body {
  background-color: #2E3440;
}

.switchWrapper {
	color: white;
}

.MuiButton-containedSecondary {
	background-color: #D8DEE9 !important;
	color: black !important;
}