.compooterTableWrapper {
  display: flex;
  justify-content: center;
  /* flex-wrap: nowrap;
  flex-basis: 0; */
  align-items: stretch;
}

.t1, .t2, .t3, .t4, .t5 {
  flex-grow: 4;
}

/* td:nth-child(2) {
  width: 70%;
}; */
